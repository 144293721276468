import OrganicCardTimeline from '~/components/Molecules/Organic/CardTimeline/CardTimeline.vue'
import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    description: {
      type: String,
    },

    cards: {
      type: Array,
    },

    cta: {
      type: Object,
    },
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  data() {
    return {
      OrganicCardTimeline: OrganicCardTimeline,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 54,
        resistanceRatio: 0,
        breakpoints: {
          1440: {
            slidesPerView: 3.5,
            slidesPerGroup: 3,
            spaceBetween: 54,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 54,
          },
          600: {
            slidesPerView: 2.1,
            slidesPerGroup: 2,
            spaceBetween: 22,
          },
          320: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 24,
          },
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
    }
  },

  computed: {
    hasDescription() {
      return Boolean(this.description)
    },

    hasCta() {
      return Boolean(this.cta)
    },
  },
}
